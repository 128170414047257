import 'cookieconsent';
import { newTracker, setUserId, trackPageView } from '@snowplow/browser-tracker';
import IMask from 'imask';
import '../assets/js/webflow/revealbot';

window.IMask = IMask;

newTracker('sp', 'https://tracking.revealbot.com', { appId: 'revealbot' });
if (window.USER_ID) {
  setUserId(window.USER_ID);
}

if (window.SNOWPLOW_ENABLED) {
  trackPageView();
}

window.cookieconsent.initialise({
  palette: {
    popup: {
      background: '#f5f6f7',
      text: '0c0d1e',
    },
    button: {
      background: '#0c0d1e',
    },
  },
  content: {
    message: 'Revealbot uses cookies to ensure you get the best experience on our website.',
    href: '/legal/cookies',
  },
});

window.flashNotify = (value, type) => {
  let style = 'position: fixed; top: 20px; right: 20px; border-radius: 4px; padding: 15px; z-index: 1000; ';

  style +=
    type === 'success'
      ? 'color: #3c763d; background-color: #dff0d8; border: 1px solid #d6e9c6'
      : 'color: #a94442; background-color: #f2dede; border: 1px solid #ebccd1';

  document.querySelector('body').innerHTML += `<div class="flash-notify" style="${style}">${value}</div>`;
  setTimeout(() => document.querySelector('.flash-notify').remove(), 5000);
};
